import React from "react"
import ContactForm from "./ContactForm"

const Newsletter = () => {
  return (
    <div className="container rounded-lg my-5 p-5">
      <h2 className="text-3xl sm:text-4xl font-semibold text-gray-900 leading-tight tracking-tight text-right">
        לקבלת ייעוץ השאירו פרטים
      </h2>
      <div className="flex py-5 text-right">
      <ContactForm/>
      </div>
    </div>
  )
}

export default Newsletter
